<template>
  <v-container fill-height>
    <v-layout align-top justify-center>
      <v-flex xs12 sm12 md10 lg10>
        <v-sheet color="white" flat class="pa-2">
          <v-data-table
            :headers="headers"
            :items="Reports"
            :items-per-page="10"
            item-key="id"
            mobile-breakpoint="0"
            sort-by="name"
            class="elevation-0"
            :loading="loading_status"
            loader-height="10"
            loading-text="Cargando ..."
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Reportes de Caja</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-text-field
                  v-model="issueDate"
                  label="Fecha"
                  hide-details="auto"
                  required
                  dense
                  outlined
                  type="date"
                  @input="get_notes"
                />
                <v-spacer></v-spacer>
                <v-btn icon @click="get_notes">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-btn x-small fab class="success" to="CashReport">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:[`item.doc_value`]="{ item }">
              {{ item.doc_value }}
            </template>
            <template v-slot:[`item.dian_id`]="{ item }">
              <v-icon small class="mr-2" v-if="item.dian_id"> mdi-check-all </v-icon>
            </template>
            <template v-slot:[`item.cufe_n`]="{ item }">
              <v-icon small class="mr-2" v-if="item.cufe_n"> mdi-check-all </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="get_e_doc(item)"> mdi-magnify </v-icon>
            </template>
          </v-data-table>
        </v-sheet>
      </v-flex>
    </v-layout>
    <noteViewer
      :item="item"
      :dialog="dialog"
      @close="dialog = false"
      @refresh="refresh()"
    />
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
import noteViewer from "../components/doc_viewer_report.vue";

export default {
  components: { noteViewer },
  data() {
    return {
      errors: [],
      valid: false,
      loading_status: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      imageUrl: null,
      dialog: false,
      headers: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issuedate",
          dataType: "text",
        },
        {
          text: "Documento",
          align: "start",
          sortable: true,
          value: "doc_number",
          dataType: "text",
        },
        {
          text: "Cajero",
          align: "start",
          sortable: true,
          value: "userId",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "doc_value",
          dataType: "number",
        },
        { text: "", align: "end", value: "actions", sortable: false, width: "5" },
      ],
      Reports: [],
      item: { doc: null, items: [], payments: [] },
      issueDate: getToday(),
    };
  },
  mounted() {
    this.get_notes();
  },
  methods: {
    refresh() {
      this.get_notes();
    },
    get_notes() {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        table: "docs",
      };
      qry.filters = [
        {
          field: "doc_type",
          operator: "=",
          value: "RPC",
        },
        {
          field: "DATE(issuedate)",
          operator: "=",
          value: this.issueDate,
        },
        {
          field: "store",
          operator: "=",
          value: window.store.store_id,
        },
      ];
      if (this.$store.getters.profile.user_access == "USUARIO") {
        qry.filters.push({
          field: "userId",
          operator: "=",
          value: this.$store.state.profile.email,
        });
      }
      console.log(qry);
      webserver("get_dataTable", qry, (data) => {
        console.log("get_dataTable =>", data);
        this.loading_status = false;
        this.Reports = data;
      });
    },
    get_e_doc(e) {
      var issudate = e.issuedate.split(" ");
      console.log(issudate[0]);
      var doc = {
        store: e.store,
        issueDate: issudate[0],
        did: e.doc_number,
      };

      webserver("get_invoice_fe", doc, (data) => {
        this.item = data;
        this.dialog = true;
        // data.doc.doc_type = "Invoice";
        this.loading_vendors = false;
      });
    },
  },
};
</script>

<style></style>
